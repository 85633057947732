import Axios from 'axios';
import ApiError from './ApiError';
import ERRORS from './errorConstants';
import { appConstant } from '@constants';
import { message } from 'antd';
import { APP_VARIABLES } from '@constants/app-constants';
import userManager from 'src/config/user-manager';

const setupInterceptors = (store) => {
	// Default settings for Axios request
	Axios.defaults.baseURL = appConstant.domain;
	Axios.defaults.headers.post['Content-Type'] = 'application/json';
	Axios.defaults.validateStatus = () => true;
	Axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN);
			if (token) {
				const access_token = token && JSON.parse(token).access_token;

				if (config.url && config.url.includes('vimeo')) {
					config.headers.Authorization = `Bearer ${process.env.REACT_APP_VIMEO_BEARERKEY}`;
				} else {
					config.headers.Authorization = `Bearer ${access_token}`;
				}
			}
			return config;
		},
		(error) => Promise.reject(error)
	);
	Axios.interceptors.response.use(
		(response) => {
			// Process response body
			const trueString = (val) => {
				return val && typeof val === 'string' && val.length < 50;
			};
			if (response.status && parseInt(response.status, 0) > 205) {
				const resMessage = response.data
					? typeof response.data === 'object'
					: trueString(response.data.Message) === true
					? response.data.Message
					: trueString(response.data) === true
					? response.data
					: 'Something went wrong';

				// console.log('resMessage', resMessage, typeof response.data === 'object');
				switch (response.status) {
					case 500:
						message.error('Something went wrong');
						throw new ApiError(ERRORS.SERVER_ERROR);
					case 401:
						message.info('Redirecting...');
						new Promise(function (resolve, reject) {
							localStorage.removeItem(APP_VARIABLES.ACCESS_TOKEN);
							localStorage.removeItem(APP_VARIABLES.CORPORATE_ID);
							localStorage.removeItem(APP_VARIABLES.CORPORATE_NAME);
							localStorage.removeItem(APP_VARIABLES.USER_DETAILS);
							localStorage.removeItem('amount');
							localStorage.removeItem('transferFundsPopupVisible');
							localStorage.removeItem('showUnemploymentBanner');
							localStorage.clear();
							resolve();
						}).then(() => {
							// debugger;
							userManager.signoutRedirect({ id_token_hint: localStorage.getItem(APP_VARIABLES.ID_TOKEN) });
							userManager.removeUser();
						});
						throw new ApiError(ERRORS.UNAUTHORISED_ERROR, response.data?.error?.message);
					case 403:
						// localStorage.removeItem(APP_VARIABLES.ACCESS_TOKEN);
						// userManager.removeUser();
						// userManager.signoutRedirect({ id_token_hint: localStorage.getItem(APP_VARIABLES.ID_TOKEN) });
						// localStorage.removeItem(APP_VARIABLES.CORPORATE_ID);
						// localStorage.removeItem(APP_VARIABLES.CORPORATE_NAME);
						// localStorage.removeItem(APP_VARIABLES.USER_DETAILS);
						// localStorage.removeItem('amount');
						new Promise(function (resolve, reject) {
							localStorage.removeItem(APP_VARIABLES.ACCESS_TOKEN);
							localStorage.removeItem(APP_VARIABLES.CORPORATE_ID);
							localStorage.removeItem(APP_VARIABLES.CORPORATE_NAME);
							localStorage.removeItem(APP_VARIABLES.USER_DETAILS);
							localStorage.removeItem('amount');
							localStorage.removeItem('transferFundsPopupVisible');
							localStorage.removeItem('showUnemploymentBanner');
							localStorage.clear();
							resolve();
						}).then(() => {
							// debugger;
							userManager.signoutRedirect({ id_token_hint: localStorage.getItem(APP_VARIABLES.ID_TOKEN) });
							userManager.removeUser();
						});
						message.error(resMessage);
						throw new ApiError(
							ERRORS.FORBIDDEN_ERROR,
							response && response.data && response.data.Message ? response.data.Message : 'Network Error'
						);
					case 406:
						message.error(resMessage);
						throw new ApiError(ERRORS.UNAUTHORISED_ERROR, response.data.error.message);
					case 400:
						const responseData =
							typeof response?.data === 'string' && response.data.length > 0 && response.data.length <= 200 && response?.data;
						let mes = !!resMessage ? resMessage : responseData ? responseData : 'Something went wrong';
						message.error(mes);
						throw new ApiError(ERRORS.UNAUTHORISED_ERROR, response.data);
					case 409: // Conflict error
						const messageResponse = response?.data?.message;
						if (messageResponse) {
							message.warn(messageResponse);
						} else {
							message.error('Something went wrong');
						}
						throw new ApiError({
							name: 'CONFLICT_ERROR',
							message: messageResponse || 'Something went wrong',
						});
					case 404:
						message.error(resMessage);
						throw new ApiError(ERRORS.CLIENT_ERROR);
					default:
						message.error(resMessage);
						throw new ApiError(ERRORS.CLIENT_ERROR);
				}
			} else {
				return response;
			}
		},
		(error) => Promise.reject(error)
	);
};

/**
 * Set auth token as default in Axios
 * @param token
 */
export const setAuthToken = (token = localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN)) => {
	Axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN)}`;
};
export default setupInterceptors;
